import React from "react"
import { Helmet } from "react-helmet"
import "../../static/assets/scss/pages/page.scss"
import LayoutDark from "./shared/layout/layout-dark"
import queryString from "query-string"
import SignUpFormComponent from "./shared/components/sign-up-form.component"

const routes = require("../types/routes")

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
    this.form = React.createRef()
    this.firstName = React.createRef()
    this.lastName = React.createRef()
    this.email = React.createRef()
    this.message = React.createRef()
    this.button = React.createRef()
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)

    if (values.email) {
      this.email.current.value = values.email
    }
  }

  onButtonStateLoading() {
    this.button.current.classList.add("spinner", "loading")
  }

  onButtonStateDefault() {
    this.button.current.classList.remove("spinner", "disabled", "loading")
  }

  onValidate() {
    const fields = [
      this.firstName.current,
      this.lastName.current,
      this.email.current,
      this.message.current,
    ]
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const namePattern = /^[a-zA-Z\s]*$/
    const emailRegex = RegExp(emailPattern)
    const textRegex = RegExp(namePattern)
    let isValid = true
    for (const field of fields) {
      if (field.id === "email") {
        const valid = emailRegex.test(field.value) && field.value.length > 0
        valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
        if (valid === false) {
          isValid = false
        }
      } else {
        const valid = textRegex.test(field.value) && field.value.length > 0
        valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
        if (valid === false) {
          isValid = false
        }
      }
    }
    return isValid
  }

  onMarkAsInvalid(field) {
    field.parentNode.classList.add("error")
    this.button.current.classList.add("disabled")
  }

  onMarkAsValid(field) {
    field.parentNode.classList.remove("error")
    this.button.current.classList.remove("disabled")
  }

  onEnterKeypress(e) {
    this.onMarkAsValid(e.target)
    if (e.key === "Enter") {
      e.preventDefault()
      this.onSubmitForm(e)
    }
  }

  onSubmitForm(e) {
    this.onButtonStateLoading()
    if (this.onValidate()) {
      const formData = new FormData(this.form.current)
      const data = {
        fields: [
          { name: "firstname", value: formData.get("firstname") },
          { name: "lastname", value: formData.get("lastname") },
          { name: "email", value: formData.get("email") },
          { name: "message", value: formData.get("message") },
        ],
      }
      this.ajax(
        this.form.current.method,
        this.form.current.action,
        data,
        data => {
          this.onButtonStateDefault()
          window.location.reload()
        },
        (error, data) => {
          const resp = JSON.parse(data)
          const message = resp.errors[0].message
          this.onButtonStateDefault()
          this.onMarkAsInvalid(message)
        }
      )
    } else {
      setTimeout(() => {
        this.onButtonStateDefault()
      }, 500)
    }
  }

  ajax(method, url, data, success, error) {
    var xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        success(xhr.response, xhr.responseType)
      } else {
        error(xhr.status, xhr.response, xhr.responseType)
      }
    }
    xhr.send(JSON.stringify(data))
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Contact Us</title>
          <meta name="title" content="Contingent: Contact Us" />
          <meta
            name="description"
            content="Capturing, verifying and monitoring supplier & business risk data is painful.
Remove this pain with one single source of truth. Contingent."
          />
          <meta property="og:type" content="website" />
          <link rel="canonical" href={`${process.env.BASE_URL}/contact-us/`} />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/contact-us/`}
          />
          <meta property="og:title" content="Contingent: Contact Us" />
          <meta
            property="og:description"
            content="Capturing, verifying and monitoring supplier & business risk data is painful.
Remove this pain with one single source of truth. Contingent."
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/contact-us/`}
          />
          <meta property="twitter:title" content="Contingent: Contact Us" />
          <meta
            property="twitter:description"
            content="Capturing, verifying and monitoring supplier & business risk data is painful.
Remove this pain with one single source of truth. Contingent."
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic world-background"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column centered"}>
                  <div className={"content"}>
                    <h1 className={"title"}>Contact us</h1>
                    <p className={"description"}>
                      Want to learn more about the all-in-one business
                      automation platform? Submit this form and our sales
                      representative will contact you soon.
                    </p>
                  </div>
                  <form
                    className={"form dark-form"}
                    ref={this.form}
                    method={"post"}
                    action={
                      "https://api.hsforms.com/submissions/v3/integration/submit/8614381/7dde2b37-8761-4c25-b6d8-5763886d22e0"
                    }
                  >
                    <div className={"body"}>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>First name</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.firstName}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="firstname"
                            type={"text"}
                            placeholder={"e.g Tony"}
                          />
                          <div className={"error-text"}>
                            Please provide a valid first name
                          </div>
                        </div>
                      </div>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>Last name</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.lastName}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="lastname"
                            type={"text"}
                            placeholder={"e.g Stark"}
                          />
                          <div className={"error-text"}>
                            Please provide a valid last name
                          </div>
                        </div>
                      </div>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>Email</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.email}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="email"
                            type={"text"}
                            id="email"
                            placeholder={"e.g tony@starkenterprise.com"}
                          />
                          <div className={"error-text"}>
                            Please provide a valid email
                          </div>
                        </div>
                      </div>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>Message</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <textarea
                            ref={this.message}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="message"
                            placeholder={"e.g Stark Enterprises"}
                          />
                          <div className={"error-text"}>
                            Provide a valid message
                          </div>
                        </div>
                      </div>
                      <div className={"field-container button"}>
                        <button
                          type={"button"}
                          ref={this.button}
                          onClick={e => this.onSubmitForm(e)}
                        >
                          Submit
                          <div className={"spinner"}></div>
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className={"countries"}>
                    <div className={"country"}>
                      <div className={"icon"}>
                        <img src={"/assets/images/icons/us.svg"} />
                      </div>
                      <p className={"description"}>
                        New York 85 Broad Street New York NY 10004 USA
                      </p>
                    </div>
                    <div className={"country"}>
                      <div className={"icon"}>
                        <img src={"/assets/images/icons/uk.svg"} />
                      </div>
                      <p className={"description"}>
                        9th Floor 107 Cheapside, London, United Kingdom, EC2V
                        6DN
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
